import React from 'react';
import { connect } from '../../../../common/components/runtime-context';
import { RootState } from '../../state';
import { IActionCreators } from '../../../../types/internal-types';
import { Toast, ToastSkin } from 'wix-ui-tpa';
import { classes, st } from './Toast.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import classNames from 'classnames';

type RuntimeProps = ReturnType<typeof mapRuntimeToProps>;

const SuccessToast = ({ isShown, message, actionCreators, isSuccess }: RuntimeProps) => {
  const { isMobile } = useEnvironment();
  return (
    <div className={classNames(st(classes.root, { mobile: isMobile }))}>
      <div className={classes.container}>
        <Toast
          data-hook="toast"
          skin={isSuccess ? ToastSkin.success : ToastSkin.error}
          shouldShowCloseButton
          shouldAnimate
          onClose={() => actionCreators.closeToast()}
          isShown={isShown}
        >
          {message}
        </Toast>
      </div>
    </div>
  );
};

const mapRuntimeToProps = (state: RootState, _: {}, actionCreators: IActionCreators) => {
  return {
    isShown: state.toast.isShown,
    message: state.toast.message,
    isSuccess: state.toast.isSuccess,
    actionCreators,
  };
};

export default connect(mapRuntimeToProps)(SuccessToast);
