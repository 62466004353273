import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { SubscriptionStatus, V1Subscription } from '@wix/ambassador-subscriptions-api/types';
import { addDays, isAfter } from 'date-fns';
import { Badge, BadgePriority as BADGE_PRIORITY } from 'wix-ui-tpa';
import { st, classes } from './StatusBadge.st.css';

const activeStatuses = [SubscriptionStatus.ACTIVE, SubscriptionStatus.PENDING_CANCELLATION];

const statusTranslations: Record<SubscriptionStatus, string> = {
  [SubscriptionStatus.UNDEFINED]: 'app.subscription-status.undefined',
  [SubscriptionStatus.DRAFT]: 'app.subscription-status.draft',
  [SubscriptionStatus.PENDING]: 'app.subscription-status.pending',
  [SubscriptionStatus.ACTIVE]: 'app.subscription-status.active',
  [SubscriptionStatus.EXPIRED]: 'app.subscription-status.expired',
  [SubscriptionStatus.CANCELED]: 'app.subscription-status.canceled',
  [SubscriptionStatus.PENDING_CANCELLATION]: 'app.subscription-status.pending-cancellation',
  [SubscriptionStatus.SUSPENDED]: 'app.subscription-status.suspended',
};

const isInFreeTrial = (subscription: V1Subscription) => {
  if (subscription.subscriptionStatus === SubscriptionStatus.ACTIVE && subscription.billingProfile?.trial?.period) {
    const freeTrialEnd = addDays(new Date(subscription.validFrom!), subscription.billingProfile.trial.period);
    const today = new Date();
    if (isAfter(freeTrialEnd, today)) {
      return true;
    }
  }

  return false;
};

export const getStatusTextTranslationKey = (subscription: V1Subscription) => {
  const inFreeTrial = isInFreeTrial(subscription);
  return inFreeTrial ? 'app.subscription-status.free-trial' : statusTranslations[subscription.subscriptionStatus!];
};

type Props = {
  subscription: V1Subscription;
};

export const StatusBadge = ({ subscription }: Props) => {
  const { t } = useTranslation();
  const inFreeTrial = isInFreeTrial(subscription);
  const active = inFreeTrial || activeStatuses.indexOf(subscription.subscriptionStatus!) > -1;

  return (
    <Badge className={st(classes.root, { active })} priority={BADGE_PRIORITY.default} data-hook="status-badge">
      {t(getStatusTextTranslationKey(subscription))}
    </Badge>
  );
};
