import { Subscription } from '@wix/ambassador-billing-v1-subscription/types';
import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { TextButton } from 'wix-ui-tpa';
import { connect } from '../../../../common/components/runtime-context';
import { IActionCreators } from '../../../../types/internal-types';
import { RootState } from '../../state';
import { classes } from './SubscriptionsListItemBass.st.css';

type RuntimeProps = ReturnType<typeof mapRuntimeToProps>;

interface Props {
  subscription: Subscription;
}
const UpmCta = ({ subscription, actionCreators }: Props & RuntimeProps) => {
  const { t } = useTranslation();
  return (
    <TextButton
      data-hook="upm-cta-button"
      className={classes.cta}
      onClick={() => {
        actionCreators.openUpmModal(subscription.id!);
      }}
    >
      {t('app.settings.default.update-payment.text')}
    </TextButton>
  );
};

const mapRuntimeToProps = (_state: RootState, _props: Props, actionCreators: IActionCreators) => {
  return {
    actionCreators,
  };
};

export default connect(mapRuntimeToProps)(UpmCta);
